import axios from "axios";
import { v4 as uuid } from "uuid";
import { getBotURLs, getURLs } from "../urlConfig";

export const extractTextAndLinks = (response) => {
  const regex = /(https?:\/\/[^\s]+)/g;
  const links = [];
  const text = [];

  const extractedLinks = response.match(regex);
  if (extractedLinks) {
    links.push(...extractedLinks);
  }

  const extractedText = response.replace(regex, "").trim();
  const newText = extractedText.replace(/\n/g, "").trim();
  if (extractedText) {
    text.push(newText);
  }

  return { links, text };
};

export const convertToTuple = (arr) => {
  return arr?.map((data) => [data.question, data.answer]);
};

export const convertToQuestionObj = (arr) => {
  return arr?.map((data) => ({ question: data[0], answer: data[1] }));
};

export const numberWithCommas = (x, decimal = 0) => {
  return x?.toLocaleString("en-US", { minimumFractionDigits: decimal }) || 0;
};

export function generateUniqueId() {
  return "_" + Math.random().toString(36).substring(2, 9) + uuid();
}

export function convertMinutesToHoursAndMinutes(
  minutes,
  sendHoursAndMinutes = false
) {
  if (minutes < 0) {
    return "Invalid input";
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (sendHoursAndMinutes) {
    return { hours, minutes };
  } else {
    if (hours > 0 && remainingMinutes > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ${remainingMinutes} minute${
        remainingMinutes > 1 ? "s" : ""
      }`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""}`;
    } else {
      return `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
    }
  }
}

export const getSearchedCourses = (array, searchValue) => {
  return array.filter(
    (course) =>
      // Check if either the title or instructor contains the search term
      course.title.toLowerCase().includes(searchValue) ||
      course.instructor_id.name.toLowerCase().includes(searchValue)
  );
};

export const getTotalLecturesCount = (curriculum) => {
  // Ensure curriculum is an array
  if (!Array.isArray(curriculum)) {
    throw new Error("Invalid curriculum format. Expected an array.");
  }

  // Calculate the total length of lectures across all curriculum objects
  const totalLecturesCount = curriculum.reduce((total, currentCurriculum) => {
    // Ensure each curriculum object has a lectures array
    if (currentCurriculum && Array.isArray(currentCurriculum.lectures)) {
      return total + currentCurriculum.lectures.length;
    } else {
      return total;
    }
  }, 0);

  return totalLecturesCount;
};

export const getSentenceWithoutLastWord = (sentence) => {
  if (!sentence) {
    return "";
  }
  // Split the sentence by spaces
  const words = sentence?.split(" ");
  // Remove the last word from the array
  words?.pop();
  // Join the remaining words back into a sentence
  return words?.join(" ");
};

export const getLastWord = (sentence) => {
  if (!sentence) {
    return "";
  }
  // Split the sentence by spaces
  const words = sentence?.split(" ");
  // Return the last word
  return words?.[words.length - 1];
};

// functin to save chat history to session
export const saveChatToSession = (tutor, history) => {
  sessionStorage.setItem(`${tutor}-chat-history`, JSON.stringify(history));
};

// utility func to add suffix to number
export function addSuffixToNumber(number) {
  const num = parseInt(number);

  if (isNaN(num)) {
    return "Invalid input";
  }

  const lastDigit = num % 10;
  const secondLastDigit = Math.floor(num / 10) % 10;

  if (secondLastDigit === 1) {
    return num + "th";
  }

  switch (lastDigit) {
    case 1:
      return num + "st";
    case 2:
      return num + "nd";
    case 3:
      return num + "rd";
    default:
      return num + "th";
  }
}

export function roundDownToNearest10(number) {
  number = parseInt(number);
  if (number < 10) {
    return number;
  } else {
    return Math.floor(number / 10) * 10;
  }
}

// custom toolbar for text editor
export const ToolbarOptions = [
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [{ header: 1 }, { header: 2 }], // custom button values
  ["link", "image", "video", "formula"],
];

export const isHTML = (content) => {
  const doc = new DOMParser().parseFromString(content, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

export const getMonthlyEarnings = (monthlyEarningObject) => {
  const yearMonth = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}`;

  return monthlyEarningObject?.[yearMonth] || 0;
};

// export const parseQuizContent = (markdown) => {
//   const quiz = [];
//   const lines = markdown.split("\n");
//   const keySection = markdown.split("### Key")?.[1]?.trim().split("\n");
//   const correctAnswers = {};

//   // Extract the correct answers from the key section
//   keySection?.forEach((line) => {
//     const [questionNum, answer] = line.split(".");
//     correctAnswers[questionNum.trim()] = answer?.trim();
//   });

//   let currentQuestion = null;
//   lines.forEach((line) => {
//     if (
//       /^#{1,6}\s*\d+\.\s*\*\*/.test(line) ||
//       /^\d+\.\s*\*\*/.test(line) ||
//       /^#{1,6}\s*\d+\.\s*/.test(line)
//     ) {
//       // New question detected
//       if (currentQuestion) {
//         quiz.push(currentQuestion);
//       }
//       currentQuestion = {
//         question: line.replace(/^#+\s*\d+\.\s*/, "").trim(),
//         options: [],
//         correctAnswer: null,
//       };
//     } else if (/^\s*[-a-dA-D]\s*[.)]?\s/.test(line.trim())) {
//       // New option detected
//       currentQuestion.options.push(line.replace(/^\s*-\s*/, "").trim());
//     }
//   });

//   if (currentQuestion) {
//     quiz.push(currentQuestion);
//   }

//   // Attach correct answers to each question
//   quiz.forEach((q, index) => {
//     q.correctAnswer = correctAnswers[index + 1];
//   });

//   return quiz;
// };

export function parseQuizContent(markdownContent) {
  const lines = markdownContent.split("\n");
  const questions = [];
  let currentQuestion = null;
  let readingOptions = false;
  let readingAnswer = false;
  let readingExplanation = false;

  const questionRegex = /^(###|Question\s*\d*\.?|^\d+\.)\s*(.+)$/i;
  const optionRegex = /^(\-|\*|\d+\)|\d+\.)\s*(.+)$/;
  const correctAnswerRegex = /^\*\*Correct Answer:\*\*|Key/i;
  const explanationRegex = /^\*\*Explanation:\*\*|Explanation/i;

  lines.forEach((line, index) => {
    line = line.trim();

    if (questionRegex.test(line)) {
      if (currentQuestion) {
        questions.push(currentQuestion);
      }
      const matches = questionRegex.exec(line);
      currentQuestion = {
        question: matches[2].trim(),
        options: [],
        correctAnswer: "",
        explanation: "",
      };
      readingOptions = true;
      readingAnswer = false;
      readingExplanation = false;
    } else if (readingOptions && optionRegex.test(line)) {
      const matches = optionRegex.exec(line);
      currentQuestion.options.push(matches[2].trim());
    } else if (correctAnswerRegex.test(line)) {
      readingOptions = false;
      readingAnswer = true;
      readingExplanation = false;
      const answerLine = lines[index + 1] ? lines[index + 1].trim() : "";
      currentQuestion.correctAnswer = answerLine
        .replace(/^\*\*Correct Answer:\*\*|\*\*Key:\*\*/i, "")
        .trim();
    } else if (explanationRegex.test(line)) {
      readingOptions = false;
      readingAnswer = false;
      readingExplanation = true;
      currentQuestion.explanation = line
        .replace(/^\*\*Explanation:\*\*/i, "")
        .trim();
    } else if (readingExplanation) {
      currentQuestion.explanation += " " + line;
    }
  });

  if (currentQuestion) {
    questions.push(currentQuestion);
  }

  return questions;
}

// Recursive function to extract plain text from the result-content element
// const getResultContentText = (element) => {
//   let text = "";
//   const childNodes = element.childNodes;
//   for (let i = 0; i < childNodes.length; i++) {
//     const node = childNodes[i];
//     if (node.nodeType === Node.TEXT_NODE) {
//       text += node.textContent;
//     } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName !== "A") {
//       text += getResultContentText(node);
//     }
//   }
//   return text;
// };

// useEffect(() => {
//     // fetching the initial chat history
//     if (Object.keys(userInfo).length) {
//       axios
//         .get(getURLs("get-chat"), {
//           headers: {
//             "auth-token": userInfo?.authToken,
//           },
//           withCredentials: true,
//         })
//         .then((res) => {
//           const history = res.data.chatHistory;
//           const convertedMathChatHistory = history?.mathChatHistory?.map(
//             (item) => [item.question, item.answer]
//           );
//           const convertedScienceChatHistory = history?.scienceChatHistory?.map(
//             (item) => [item.question, item.answer]
//           );
//           setMathsTotalChats(convertedMathChatHistory);
//           setScienceTotalChats(convertedScienceChatHistory);
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   }, [userInfo]);
